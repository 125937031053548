var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("הוספת קופון")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c(
              "b-field",
              { attrs: { label: "הזן קוד לקופון" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.code,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "code", $$v)
                    },
                    expression: "model.code"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן שם לקופון" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "b-radio",
                  {
                    attrs: { name: "name", "native-value": "percent" },
                    model: {
                      value: _vm.discountType,
                      callback: function($$v) {
                        _vm.discountType = $$v
                      },
                      expression: "discountType"
                    }
                  },
                  [_vm._v(" הנחה באחוזים ")]
                ),
                _c(
                  "b-radio",
                  {
                    attrs: { name: "name", "native-value": "amount" },
                    model: {
                      value: _vm.discountType,
                      callback: function($$v) {
                        _vm.discountType = $$v
                      },
                      expression: "discountType"
                    }
                  },
                  [_vm._v(" הנחה בשקלים ")]
                )
              ],
              1
            ),
            _vm.discountType == "percent"
              ? _c(
                  "b-field",
                  { attrs: { label: "הנחה באחוזים לקופון" } },
                  [
                    _c("b-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.model.discountPercentage,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "discountPercentage", $$v)
                        },
                        expression: "model.discountPercentage"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "b-field",
                  { attrs: { label: "הנחה בשקלים לקופון" } },
                  [
                    _c("b-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.model.discountAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "discountAmount", $$v)
                        },
                        expression: "model.discountAmount"
                      }
                    })
                  ],
                  1
                ),
            _c("FieldInlineDateTime", {
              attrs: {
                label: "תאריך תפוגה",
                "hide-hover": true,
                position: "is-bottom-left"
              },
              model: {
                value: _vm.model.expirationDate,
                callback: function($$v) {
                  _vm.$set(_vm.model, "expirationDate", $$v)
                },
                expression: "model.expirationDate"
              }
            }),
            _c(
              "b-field",
              { attrs: { label: "הזן מלאי" } },
              [
                _c("b-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.model.remainingUses,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "remainingUses", $$v)
                    },
                    expression: "model.remainingUses"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "סטטוס" } },
              [
                _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.model.status,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "status", $$v)
                      },
                      expression: "model.status"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("פעיל")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("לא פעיל")])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }