<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>תקצירי פסיקה</template>
      <template #button>
        <a @click="addLesson" class="link-button">
          הוספת תקציר פסיקה ליחידת הלימוד
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LessonService from "@/services/LessonService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import VerdictEntity from "@/views/VerdictEntity.vue";
import DialogAddVerdict from '@/components/DialogAddVerdict.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("verdicts");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: VerdictEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי יחידת לימוד",
        recordUrl: "lessons",
        recordTitle: "הצג יחידת לימוד",
        hideRecord: true
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.LessonId, checked: true },
        { ...lessonsFields.Name, checked: true },
        { ...lessonsFields.VerdictSummaryName, checked: true },
        { ...lessonsFields.LawCollectionName, checked: true },
        { ...lessonsFields.Category, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
        { ...lessonsFields.Status, checked: true },
      ];

      this.tabs = [
        {
          id: "AllVerdicts",
          isPrimary: true,
          title: "כל מקבצי הפסיקה",
          getDataAsync: this.getAllVerdics,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.Lessons, selected: this.$route.params.LessonIds },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מזהה יחידה",
          },
          sideViewEntity,
        },
      ];
    },
    addLesson() {
      Modal.open({
        component: DialogAddVerdict,
        props: {
          store: this.$store,
        },
        events: {
          customEvent: () => {
            this.customSearch();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    customSearch() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllVerdics({ ...rest }) {
      return LessonService.getAdminVerdicts(this.courseId, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
