var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Reports__View" },
    [
      _vm.tabs.length
        ? _c("EntityListLayout", {
            ref: "entity",
            attrs: { tabs: _vm.tabs },
            on: { onNewEntitySave: _vm.onNewEntitySave },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(
                        "ניהול הרצאות בקורס " + _vm._s(_vm.course.name) + " "
                      ),
                      _vm.LectureTrackSubjectId
                        ? _c("span", [
                            _vm._v(
                              " בנושא " + _vm._s(_vm.LectureTrackSubjectId)
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "button",
                  fn: function() {
                    return [
                      _vm.LectureTrackSubjectId
                        ? _c(
                            "a",
                            {
                              staticClass: "link-button",
                              on: { click: _vm.addLecture }
                            },
                            [_vm._v(" הוספת הרצאה ")]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "counter",
                  fn: function(slotProps) {
                    return [
                      _vm._v(' סה"כ '),
                      _c("b", [
                        _vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              1803754168
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }