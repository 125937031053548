import { render, staticRenderFns } from "./LecturesFeedbackStatementEntity.vue?vue&type=template&id=35dc762e&scoped=true&"
import script from "./LecturesFeedbackStatementEntity.vue?vue&type=script&lang=js&"
export * from "./LecturesFeedbackStatementEntity.vue?vue&type=script&lang=js&"
import style0 from "./LecturesFeedbackStatementEntity.vue?vue&type=style&index=0&id=35dc762e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35dc762e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.SimAdmin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35dc762e')) {
      api.createRecord('35dc762e', component.options)
    } else {
      api.reload('35dc762e', component.options)
    }
    module.hot.accept("./LecturesFeedbackStatementEntity.vue?vue&type=template&id=35dc762e&scoped=true&", function () {
      api.rerender('35dc762e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LecturesFeedbackStatementEntity.vue"
export default component.exports