import { render, staticRenderFns } from "./ExerciseAnswers.vue?vue&type=template&id=275c5db3&scoped=true&"
import script from "./ExerciseAnswers.vue?vue&type=script&lang=js&"
export * from "./ExerciseAnswers.vue?vue&type=script&lang=js&"
import style0 from "./ExerciseAnswers.vue?vue&type=style&index=0&id=275c5db3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275c5db3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.SimAdmin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('275c5db3')) {
      api.createRecord('275c5db3', component.options)
    } else {
      api.reload('275c5db3', component.options)
    }
    module.hot.accept("./ExerciseAnswers.vue?vue&type=template&id=275c5db3&scoped=true&", function () {
      api.rerender('275c5db3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ExerciseAnswers.vue"
export default component.exports