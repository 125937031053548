<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>נושאים במסלול {{ lecturesLearningTrack.name }}</template>
      <template #button>
        <a @click="addLectureTrackSubject" class="link-button">
          הוספת נושא למסלול
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import LectureTrackSubjectEntity from "@/views/LectureTrackSubjectEntity.vue";
import DialogAddLectureTrackSubject from '@/components/DialogAddLectureTrackSubject.vue';
import { mapActions } from "vuex";
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
    lecturesLearningTrack() {
      return this.$store.state.lecturesLearningTrack;
    },
  },
  created() {
    this.init();
    this.create();
  },
  methods: {
    ...mapActions({
      load: "lecturesLearningTrack/loadLecturesLearningTrack",
    }),
    async init(callback) {
      try {
        await this.load({
          id: this.$route.params.id,
        });
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    create() {
      const lessonsFields = FieldService.list("lecturesLearningTracks");

      const sideViewEntity = {
        component: LectureTrackSubjectEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי נושא",
        recordUrl: "videos",
        recordTitle: "הצג סרטון",
        hideRecord: true
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
        { ...lessonsFields.EditorNote, checked: true },
        { ...lessonsFields.UserNote, checked: true },
        { ...lessonsFields.AvailableFrom, checked: true },
        { ...lessonsFields.AvailableUntil, checked: true },
        { ...lessonsFields.Status, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLectureTrackSubjects",
          isPrimary: true,
          title: "כל הנושאים למסלול",
          getDataAsync: this.getLectureTrackSubjects,
          perPage: 100,
          fields,
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש לפי מזהה יחידה",
          },
          sideViewEntity,
        },
      ];
    },
    addLectureTrackSubject() {
      Modal.open({
        component: DialogAddLectureTrackSubject,
        props: {
          store: this.$store,
          lectureTrackId: this.$route.params.id
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getLectureTrackSubjects({ ...rest }) {
      return CoursesService.getCourseLecturesLearningTrackSubjects(this.$store.state.auth.course.id, this.$route.params.id, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
