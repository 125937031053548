<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>ניהול הרצאות בקורס {{ course.name }} <span v-if="LectureTrackSubjectId"> בנושא {{
    LectureTrackSubjectId }}</span></template>
      <template #button>
        <a v-if="LectureTrackSubjectId" @click="addLecture" class="link-button">
          הוספת הרצאה
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import LectureEntity from "@/views/LectureEntity.vue";
import DialogAddLecture from '@/components/DialogAddLecture.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    LectureTrackSubjectId() {
      return this.$route.params.id;
    },
    course() {
      return this.$store.state.auth.course;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("lectures");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: LectureEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי הרצאה",
        recordUrl: "books",
        recordTitle: "הצג ספר",
        hideRecord: true
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
      ];
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.StartTime, checked: true },
        { ...lessonsFields.Day, checked: true },
        { ...lessonsFields.SubjectName, checked: true },
        { ...lessonsFields.TrackName, checked: true },
        { ...lessonsFields.TeacherName, checked: true },
        { ...lessonsFields.Address, checked: true },
        { ...lessonsFields.HallName, checked: true },
        { ...lessonsFields.Count, checked: true },
        { ...lessonsFields.Status, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLectures",
          isPrimary: true,
          title: "כל ההרצאות",
          getDataAsync: this.getAllLectures,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.LectureTrackSubjectId, selected: this.$route.params.id },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
        },
      ];
    },
    addLecture() {
      Modal.open({
        component: DialogAddLecture,
        props: {
          store: this.$store,
          lectureTrackSubjectId: this.$route.params.id
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }) {
      loading(true);
      CoursesService.exportCouresLectures(this.course.id, query)
        .then((r) => {
          this.saveFile(r.data, `ייצוא הרצאות`);
        })
        .finally(() => {
          loading(false);
        });
    },
    getAllLectures({ ...rest }) {
      return CoursesService.getCourseLectures(this.course.id, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
