import { render, staticRenderFns } from "./ExpoChapterQuestions.vue?vue&type=template&id=01905bcc&scoped=true&"
import script from "./ExpoChapterQuestions.vue?vue&type=script&lang=js&"
export * from "./ExpoChapterQuestions.vue?vue&type=script&lang=js&"
import style0 from "./ExpoChapterQuestions.vue?vue&type=style&index=0&id=01905bcc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01905bcc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.SimAdmin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01905bcc')) {
      api.createRecord('01905bcc', component.options)
    } else {
      api.reload('01905bcc', component.options)
    }
    module.hot.accept("./ExpoChapterQuestions.vue?vue&type=template&id=01905bcc&scoped=true&", function () {
      api.rerender('01905bcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ExpoChapterQuestions.vue"
export default component.exports