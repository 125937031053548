<template>
  <div v-if="exerciseName" id="exercisePrint">
    <button class="print-report-btn" @click.prevent="print">הדפסה</button>
    <div class="exercise-title">
      <div style="font-size: 2em">תרגול: <span style="font-weight: bold;">{{ exerciseName }}</span></div>
      <div style="font-size: 2em">מתוך: {{ categoryName || '' }}</div>
    </div>
    <div v-if="questions" v-for="(question, index) in questions" :key="question.id">
      <div class="question">
        <b>{{ index + 1 }}.</b> <span v-html="question.question"></span>
        <div v-for="answer in question.answers" class="answer">
          <span><b>{{ answer.sortOrder | letterize }}</b></span>
          <span v-html="answer.answer"></span>
          <b-icon v-if="answer.isCorrect" style="color: green" icon="check" > </b-icon>
        </div>
        <div v-if="question.detailedAnswer" class="questionText">
          <div>תשובה מפורטת</div>
          <span v-html="question.detailedAnswer"></span>
        </div>
      </div>
    </div>
    <div class="signature">הודפס ממערכת הסימולטור - המתמחה מבית נבו</div>
  </div>
</template>
<script>
import LessonService from '../services/LessonService';


export default {
  name: "ExercisePrint",
  filters: {
    letterize: function (value) {
      switch (value) {
        case 1: return 'א.'; break;
        case 2: return 'ב.'; break;
        case 3: return 'ג.'; break;
        case 4: return 'ד.'; break;
        default: return;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      exerciseName: null,
      categoryName: null,
      questions: null
    };
  },
  components: {

  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
  },
  created() {
    const showAnswers = this.$route.query.showAnswers;
    LessonService.printExercise(this.$route.params.exerciseId, showAnswers)
      .then((r) => {
        this.exerciseName = r.data.exerciseName;
        this.categoryName = r.data.categoryName;
        this.questions = r.data.questions;
      });
  },
  methods: {
    print: function (solution) {
      var printed = document.execCommand('print', false, null);
      if (!printed) {
        window.print();
      }
    },
  },
  watch: {
  },
};
</script>
<style scoped lang="scss">

.exercise-title {
  padding: 15px;
}

#exercisePrint {
  width: 100%;
  position: relative;
  font-size: 16px;
  background: #FFF;
}

#exercisePrint h1 {
  margin: 0;
}

#exercisePrint button {
  position: absolute;
  top: 4px;
  left: 20px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #333;
  border: 1px solid #ccc;
}

#exercisePrint button:hover {
  background: #DDD;
}

#exercisePrint .signature {
  font-size: 12px;
}

@media print {
  * {
    font-size: 16px !important;
  }

  .question:nth-child(2n) {
    page-break-after: always !important;
  }

  .question:nth-child(2n):last-child {
    page-break-after: avoid;
  }

  .print-report-btn {
    display: none !important;
  }

}

.question {
  text-align: justify;
  padding: 30px;
  line-height: 1.5;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.question:first-of-type {
  border-top: 1px solid #ddd;
}

.question .answer {
  padding: 10px;
}

.questionText {
  padding: 10px;
  box-shadow: 0px 0px 15px 2px rgba(177,177,179,1);
  margin: 25px 0;
}

.questionText>div {
  font-weight: bold;
  padding: 10px;
}

@media print {
  * {
    font-size: 16px !important;
  }

  body,
  html,
  #page-container,
  .scrollable-page,
  .ps,
  .panel {
    height: 100% !important;
    width: 100% !important;
    display: inline-block;
  }

  .questionText {
    page-break-before: always !important;
    page-break-after: always !important;
    box-shadow: none;
  }
}
</style>
<!-- 
<style lang="scss">
.LayoutInApp__Component {
  height: 100% !important;
}
</style> -->
