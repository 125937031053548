<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>ספרים</template>
      <template #button>
        <a @click="addLessonBook" class="link-button">
          שיוך ספר לקורס
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LessonService from "@/services/LessonService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import BookEntity from "@/views/BookEntity.vue";
import DialogAddLessonBook from '@/components/DialogAddLessonBook.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
      this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("courseBooks");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: BookEntity,
        preview: true,
        title: "פרטי ספר",
        recordUrl: "books",
        recordTitle: "הצג ספר",
        hideRecord: true
      };
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.BookName, checked: true },
        { ...lessonsFields.LessonName, checked: true },
        { ...lessonsFields.BookAuthor, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
        { ...lessonsFields.Status, checked: true },
      ];

      this.tabs = [
        {
          id: "AllBooks",
          isPrimary: true,
          title: "כל הספרים",
          getDataAsync: this.getAllBooks,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.Lessons, selected: this.$route.params.LessonIds },
          ],
          sortField: "bookId",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
        },
      ];
    },
    addLessonBook() {
      Modal.open({
        component: DialogAddLessonBook,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllBooks({ ...rest }) {
      return LessonService.getAdminBooks(this.courseId, {
        ...rest
      }).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
